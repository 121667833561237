import React from 'react'
import { Row, Col, ButtonGroup } from 'react-bootstrap'

// Components
import UIPanelInfo from '../../../../../UI/Panel/Info'
import UIPanelInfoBlock from '../../../../../UI/Panel/Info/Block'
import UIPanelInfoItem from '../../../../../UI/Panel/Info/Item'
import UIFormInputSelect from '../../../../../UI/FormReload/Input/Select'
import UIFormInputTextarea from '../../../../../UI/FormReload/Input/Textarea'
import UIFormInputText from '../../../../../UI/FormReload/Input/Text'
import UILoading from '../../../../../UI/Loading'
import UIError from '../../../../../UI/Error'
import UIButton from '../../../../../UI/Button'

// Hooks
import {
  useTrigger,
  useEffect,
  useState,
  useQuery,
  useGlobal,
} from '../../../../../../hooks'

// Queries
import {
  GET_SAMPLE_TRACKING_STATES,
  GET_SAMPLE_ANOMALIES,
  GET_SAMPLE_INFO,
  GET_OPENAI,
} from './queries'

import oe from '../../../../../../common/object/extractor'

const Recommendation = ({
  id,
  state,
  suggestion,
  recommendation,
  disabled,
  params,
  //  handleValueChange,
  showButton,
  choosen,
  pos,
  onChoosen,
  hkLubricant,
  ...props
}) => {
  const [skip, setSkipState] = useState(
    ![null, undefined, ''].includes(recommendation),
  )

  const [loading, setLoadingState] = useState(!skip)
  const [value, setValueState] = useState(recommendation || state + suggestion)

  const { data, refetch } = useQuery(GET_OPENAI, {
    fetchPolicy: 'network-only',
    skip,
    variables: { id, temperature: 0.5 },
    onErrorMessage: `No se pudo obtener el texto desde OpenAI`,
  })

  useEffect(() => {
    if (data && data.openAI && data.openAI.data && data.openAI.data.messages) {
      setLoadingState(false)
      let message = data.openAI.data.messages

      if (!!hkLubricant)
        message = `Con ${hkLubricant} horas de lubricante, ${message[0].toLowerCase()}${message.slice(
          1,
        )}`
      setValueState(message)
    }
  }, [data])

  if (loading) return <UILoading />

  const handleClick = () => {
    if (skip) setSkipState(false)
    else refetch()
    setLoadingState(true)
  }

  const handleChange = (event) => {
    setValueState(event.target.value)
  }

  return (
    <div className="text-center">
      <UIFormInputTextarea
        name="recommendation"
        className="mb-3"
        rows={7}
        value={value}
        onChange={handleChange}
        disabled={disabled}
      />
      <UIButton
        className="w-50"
        onClick={handleClick}
        disabled={loading || disabled}
      >
        Re-generar recomendación
      </UIButton>
    </div>
  )
}

//  const OpenAI = ({ id, disabled, handleValueChange, hkLubricant }) => {
//   // When there's more than one results from openai
//   const [activeOpenaiAlternatives, setActiveOpenaiAlternatives] =
//     useState(false)
//
//   const [activeOpenaiWindow, setActiveOpenaiWindow] = useState(1)
//
//   const [choosen, setChoosen] = useState(1)
//
//   return (
//     <div className="d-flex flex-column">
//       {/*<ButtonGroup className="m-3">
//         {activeOpenaiAlternatives ? (
//           <>
//             <UIButton
//               className={activeOpenaiWindow != 0 && 'active'}
//               onClick={() => setActiveOpenaiWindow(0)}
//             >
//               0.35
//             </UIButton>
//             <UIButton
//               className={activeOpenaiWindow != 1 && 'active'}
//               onClick={() => setActiveOpenaiWindow(1)}
//             >
//               0.6
//             </UIButton>
//             <UIButton
//               className={activeOpenaiWindow != 2 && 'active'}
//               onClick={() => setActiveOpenaiWindow(2)}
//             >
//               0.85
//             </UIButton>
//           </>
//         ) : (
//           <UIButton
//             className="active"
//             variant="success"
//             onClick={() => setActiveOpenaiAlternatives(true)}
//           >
//             Cargar más recomendaciones
//           </UIButton>
//         )}
//       </ButtonGroup>*/}
//       {/*activeOpenaiAlternatives && (
//         <Recomendation
//           id={id}
//           hidden={activeOpenaiWindow != 0}
//           params={{ temperature: 0.6 }}
//           disabled={disabled}
//           handleValueChange={handleValueChange}
//           showButton={activeOpenaiAlternatives}
//           choosen={choosen}
//           onChoosen={() => setChoosen(0)}
//           pos={0}
//         />
//       )*/}
//       <Recomendation
//         id={id}
//         hidden={activeOpenaiWindow != 1}
//         params={{ temperature: 0.35 }}
//         disabled={disabled}
//         handleValueChange={handleValueChange}
//         showButton={false /* activeOpenaiAlternatives */}
//         choosen={choosen}
//         onChoosen={() => setChoosen(1)}
//         pos={1}
//         hkLubricant={hkLubricant}
//       />
//       {/*activeOpenaiAlternatives && (
//         <Recomendation
//           id={id}
//           hidden={activeOpenaiWindow != 2}
//           params={{ temperature: 0.85 }}
//           disabled={disabled}
//           handleValueChange={handleValueChange}
//           showButton={activeOpenaiAlternatives}
//           choosen={choosen}
//           onChoosen={() => setChoosen(2)}
//           pos={2}
//         />
//       )*/}
//     </div>
//   )
// }
// const OpenAI = ({ id, disabled, handleValueChange, hkLubricant }) => {
//   // When there's more than one results from openai
//   const [activeOpenaiAlternatives, setActiveOpenaiAlternatives] =
//     useState(false)
//
//   const [activeOpenaiWindow, setActiveOpenaiWindow] = useState(1)
//
//   const [choosen, setChoosen] = useState(1)
//
//   return (
//     <div className="d-flex flex-column">
//       {/*<ButtonGroup className="m-3">
//         {activeOpenaiAlternatives ? (
//           <>
//             <UIButton
//               className={activeOpenaiWindow != 0 && 'active'}
//               onClick={() => setActiveOpenaiWindow(0)}
//             >
//               0.35
//             </UIButton>
//             <UIButton
//               className={activeOpenaiWindow != 1 && 'active'}
//               onClick={() => setActiveOpenaiWindow(1)}
//             >
//               0.6
//             </UIButton>
//             <UIButton
//               className={activeOpenaiWindow != 2 && 'active'}
//               onClick={() => setActiveOpenaiWindow(2)}
//             >
//               0.85
//             </UIButton>
//           </>
//         ) : (
//           <UIButton
//             className="active"
//             variant="success"
//             onClick={() => setActiveOpenaiAlternatives(true)}
//           >
//             Cargar más recomendaciones
//           </UIButton>
//         )}
//       </ButtonGroup>*/}
//       {/*activeOpenaiAlternatives && (
//         <Recomendation
//           id={id}
//           hidden={activeOpenaiWindow != 0}
//           params={{ temperature: 0.6 }}
//           disabled={disabled}
//           handleValueChange={handleValueChange}
//           showButton={activeOpenaiAlternatives}
//           choosen={choosen}
//           onChoosen={() => setChoosen(0)}
//           pos={0}
//         />
//       )*/}
//       <Recomendation
//         id={id}
//         hidden={activeOpenaiWindow != 1}
//         params={{ temperature: 0.35 }}
//         disabled={disabled}
//         handleValueChange={handleValueChange}
//         showButton={false /* activeOpenaiAlternatives */}
//         choosen={choosen}
//         onChoosen={() => setChoosen(1)}
//         pos={1}
//         hkLubricant={hkLubricant}
//       />
//       {/*activeOpenaiAlternatives && (
//         <Recomendation
//           id={id}
//           hidden={activeOpenaiWindow != 2}
//           params={{ temperature: 0.85 }}
//           disabled={disabled}
//           handleValueChange={handleValueChange}
//           showButton={activeOpenaiAlternatives}
//           choosen={choosen}
//           onChoosen={() => setChoosen(2)}
//           pos={2}
//         />
//       )*/}
//     </div>
//   )
// }

const SampleInfoBottom = ({ id, logics, results }) => {
  const [{ me }] = useGlobal()

  // --------------------------------------------------------------------------
  //
  // Get Ino
  //
  // --------------------------------------------------------------------------

  // const [values, setValuesState] = useState({
  //   state: '-',
  //   suggestion: '-',
  //   id_anomaly_type: '1',
  //   id_sample_tracking_state: '1',
  //   openai: '-',
  // })

  // This should be done in the back end
  //  useEffect(() => {
  //    if (logics && results) {
  //      // Extract essays
  //      const essays = results.essays.reduce(
  //        (p, { data }) => ({
  //          ...p,
  //          ...data.reduce(
  //            (p2, { id_essay, value, value_prev, name }) => ({
  //              ...p2,
  //              [id_essay]: { name, value, value_prev },
  //            }),
  //            {},
  //          ),
  //        }),
  //        {},
  //      )
  //
  //      const [state, suggestion] = [
  //        oe(logics, ['state', 'name'], '-'),
  //        oe(logics, ['suggestion', 'name'], '-'),
  //      ].map((s) => {
  //        // Replace essays
  //        // --------------------------------------
  //
  //        const essay_ids = {
  //          name: s.match(/{EN-[0-9]+}/g),
  //          value: s.match(/{EV-[0-9]+}/g),
  //          value_prev: s.match(/{EV-P-[0-9]+}/g),
  //        }
  //
  //        // Trim the text and parenthesis and leave only the id
  //        essay_ids.name = essay_ids.name
  //          ? essay_ids.name.map((s) => s.substring(4, s.length - 1))
  //          : []
  //
  //        essay_ids.value = essay_ids.value
  //          ? essay_ids.value.map((s) => s.substring(4, s.length - 1))
  //          : []
  //
  //        essay_ids.value_prev = essay_ids.value_prev
  //          ? essay_ids.value_prev.map((s) => s.substring(6, s.length - 1))
  //          : []
  //
  //        // Replace the values in the text
  //        s = essay_ids.name.reduce(
  //          (s, id_essay) =>
  //            s.replace(
  //              `{EN-${id_essay}}`,
  //              essays[id_essay] ? essays[id_essay].name : '??',
  //            ),
  //          s,
  //        )
  //
  //        s = essay_ids.value.reduce(
  //          (s, id_essay) =>
  //            s.replace(
  //              `{EV-${id_essay}}`,
  //              essays[id_essay] ? essays[id_essay].value : '??',
  //            ),
  //          s,
  //        )
  //
  //        s = essay_ids.value_prev.reduce(
  //          (s, id_essay) =>
  //            s.replace(
  //              `{EV-P-${id_essay}}`,
  //              essays[id_essay] ? essays[id_essay].value_prev : '??',
  //            ),
  //          s,
  //        )
  //
  //        // Replace continuity
  //        // --------------------------------------
  //
  //        s = s.replace(`{HK-C}`, results.info.current.component_continuity)
  //        s = s.replace(`{HK-L}`, results.info.current.lubricant_continuity)
  //        s = s.replace(`{HK-E}`, results.info.current.machine_continuity)
  //
  //        s = s.replace(`{HK-P-C}`, results.info.previous.component_continuity)
  //        s = s.replace(`{HK-P-L}`, results.info.previous.lubricant_continuity)
  //        s = s.replace(`{HK-P-E}`, results.info.previous.machine_continuity)
  //
  //        return s
  //      })
  //
  //      setValuesState({
  //        state,
  //        suggestion,
  //        id_anomaly_type: oe(logics, ['anomalyType', 'id'], '1'),
  //        id_sample_tracking_state: oe(logics, ['trackingState', 'id'], '1'),
  //      })
  //    }
  //  }, [logics, results])

  const { data, loading } = useQuery(GET_SAMPLE_INFO, {
    fetchPolicy: 'network-only',
    variables: { id, noise: 1 },
    onErrorMessage: `No se pudo obtener la información de la muestra`,
  })

  if (loading) return <UILoading />

  if (data && data.t4SampleInfo && data.t4SampleInfo.data) {
    const { sample, client, site, lubricant, component } =
      data.t4SampleInfo.data

    const isDisabled = sample.stage.id != 3

    return (
      <UIPanelInfo>
        <Row>
          <Col md={8}>
            <Recommendation
              id={id}
              disabled={isDisabled}
              state={oe(logics, ['state'])}
              suggestion={oe(logics, ['suggestion'])}
              recommendation={oe(logics, ['recommendation'])}
              hkLubricant={
                me.laboratory === 'collahuasi' &&
                component &&
                component.type &&
                [64, 75, 76].includes(component.type.id) &&
                lubricant &&
                !['', null, undefined].includes(lubricant.continuity)
                  ? lubricant.continuity
                  : null
              }
            />
          </Col>
          <Col md={4}>
            <UIFormInputSelect
              name="id_anomaly_type"
              disabled={isDisabled}
              defaultValue={oe(logics, ['id_anomaly_type'], '1')}
              query={GET_SAMPLE_ANOMALIES}
              label="Tipo Anomalía"
            />
            <UIFormInputSelect
              name="id_sample_tracking_state"
              disabled={isDisabled}
              defaultValue={oe(logics, ['id_sample_tracking_state'], '1')}
              query={GET_SAMPLE_TRACKING_STATES}
              label="Estado seguimiento"
            />
          </Col>
        </Row>
        <div className="font-weight-light py-5 my-2 text-center text-muted border rounded">
          <UIPanelInfoItem name="Número de muestra">
            {sample.id}
          </UIPanelInfoItem>
          <UIPanelInfoItem name="RUT cliente (Factura)">
            {client.rut}
          </UIPanelInfoItem>
          <UIPanelInfoItem name="RUT solicitante">{client.rut}</UIPanelInfoItem>
          <UIPanelInfoItem name="Número informe">
            <UIFormInputText
              name="id_report"
              disabled={isDisabled}
              style={{
                width: '200px',
                display: 'inline',
                textAlign: 'center',
              }}
              initialValue={sample.id}
              placeholder={sample.id}
            />
          </UIPanelInfoItem>
          <UIPanelInfoItem name="Glosa descriptiva">
            <UIFormInputText
              name="description"
              disabled={isDisabled}
              style={{
                width: '500px',
                display: 'inline',
                textAlign: 'center',
              }}
              initialValue={`${client.registeredName} - ${site.name}`}
              placeholder={`${client.registeredName} - ${site.name}`}
            />
          </UIPanelInfoItem>
        </div>
      </UIPanelInfo>
    )
  }

  return (
    <UIPanelInfo centered>
      <UIPanelInfoBlock>
        <UIError />
      </UIPanelInfoBlock>
    </UIPanelInfo>
  )
}

export default SampleInfoBottom
